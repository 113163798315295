const ThankYou = () => {
    return (
        <div className="bridge__container thank--you">
            <h1>This is how i found my <span>success!</span></h1>
            {/* <img src="img1.jpeg" alt="Bridge Page Image" /> */}
            {/* <video src="video-explaied.mp4"></video> */}
            <video width="1000" height="500" controls poster="thumbnail.png" >
                <source src="./video-explained.mp4" type="video/mp4"/>
            </video>
            {/*  */}
            <div className="form">
                <a href="https://www.tubebuddy.com/dudeGrowth" target="_blank">
                    Get Tubebuddy For Free Here!
                </a>
            </div>
        </div>
    );
}
 
export default ThankYou