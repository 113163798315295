import { useState } from 'react';
import axios from 'axios'
import { useHistory } from 'react-router-dom'

const BridgePage = () => {

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const history = useHistory()

    const handleEmailSend = (param) => {
        setError(null)

        if (email === "" && validateEmail(param) === false) {
            console.log("Email is blank, or wrong")
            setError("Email is blank, or wrong")
        } else {
            sendEmail(param)
        }
    }

    const sendEmail = (param) => {
        setLoading(true)

        try {
            history.push('/thanks')
            axios.post("https://632e26c32cfd5ccc2afcd24b.mockapi.io/api/v1/emails", {"email": param}).then(response => {
                setLoading(false)
            })
        } catch (e) {
            console.log(e)
        }
    }

    const validateEmail = (param) => {
        let re = /\S+@\S+\.\S+/;
        return re.test(param);
    }

    return (
        <div className="bridge__container">
            <h1><span>Tired</span> of Making Youtube Videos And Not Getting Any Subscriber?</h1>
            <p>This <span>Free Simple Tool</span> Will Help You Start Getting New Subscribers Within Days.</p>
            <img src="img1.jpeg" alt="Bridge Page Image" />
            {/*  */}
            <div className="form">
                <input type="text" value={email} onInput={e => setEmail(e.target.value)} placeholder="Email*"/>
                <span className="error">
                    { error }
                </span>
                <a onClick={() => handleEmailSend(email)}>
                    {loading ? 'Loading...Please Wait' : 'Watch Free Video Now!'}
                </a>
            </div>
        </div>
    );
}
 
export default BridgePage;