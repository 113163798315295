import BridgePage from "./BridgePage";
import HeadBar from "./HeadBar";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ThankYou from "./ThankYou";

function App() {
  return (
    <Router>
      <div className="App">
        <HeadBar title="100% FREE TRAINING AND TOOL"/>
        <Switch>
          <Route exact path="/">
            <BridgePage />
          </Route>
          <Route path="/thanks">
            <ThankYou />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
